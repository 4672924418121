import React, { FC, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, PasswordInput, Skeleton } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { api, ICoachInvitationCompleteRequest } from '@chess-class/sandbox';
import { IllustrationLogoIcon, IllustrationPawnGoodYellowHalf } from '@chess-class/sandbox/icons';

import { PageMeta } from '~/context/PageMetaContext';

const AuthInvitationPage: FC = () => {
  const { link = '' } = useParams<{ link: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const supportEmailQuery = api.settings.useSettings({ settingNames: ['SUPPORT_EMAIL'] });
  const supportEmail = supportEmailQuery.data?.data.content[0].string || '';

  const invitationComplete = api.user.coachAuth.useCoachInvitationComplete();
  const inviteCheck = api.user.coachAuth.useCoachInviteCheck();
  const status = inviteCheck.data?.data.status;

  const form = useForm<ICoachInvitationCompleteRequest>({
    initialValues: {
      ...api.user.coachAuth.helpers.coachInvitationCompleteInitial,
      linkCode: link || '',
    },
    validate: yupResolver(api.user.coachAuth.helpers.coachInvitationCompleteValidation),
  });

  useEffect(() => {
    inviteCheck.mutate({ linkCode: link });
  }, []);

  return (
    <div className="flex flex-col gap-8 items-center">
      <PageMeta title={t('auth.enterNewPassword')} />
      {!status ? (
        <>
          <Skeleton className="w-2/3 h-10 rounded-lg" />
          <Skeleton className="h-40 rounded-lg" />
          <Skeleton className="h-14 rounded-lg" />
          <Skeleton className="h-14 rounded-lg" />
        </>
      ) : status == 'INVALID_LINK_CODE' ? (
        <>
          <div className="flex flex-col gap-2 items-center">
            <IllustrationLogoIcon className="h-12 w-12" />
            <h1 className="text-primary font-bold">Chess Class</h1>
          </div>
          <span className="font-semibold text-center text-gray-900 text-lg">
            {t('auth.inviteWrongCode')}
          </span>
          {/*<IllustrationPawnSearchYellowIcon className="h-28 w-28" />*/}
          <span className="text-center font-medium text-gray-500 text-sm">
            {t('helpModal.helpText')}{' '}
            {!supportEmail ? (
              <LoadingOutlined className="ml-1" />
            ) : (
              <a className="my-6" href={`mailto:${supportEmail}`}>
                {supportEmail}
              </a>
            )}
          </span>
          <Link className="text-center w-full" to="/auth/signin">
            <Button className="mt-2 w-full text-base">{t('goBack')}</Button>
          </Link>
        </>
      ) : status == 'ALREADY_INVITED' ? (
        <>
          <div className="flex flex-col gap-2 items-center">
            <IllustrationLogoIcon className="h-12 w-12" />
            <h1 className="text-primary font-bold">Chess Class</h1>
          </div>
          <span className="font-semibold text-center text-gray-900 text-lg">
            {t('auth.inviteSchoolAccepted')}
          </span>
          <IllustrationPawnGoodYellowHalf className="h-44 w-44" />

          <Link className="text-center w-full" to="/auth/signin">
            <Button className="mt-2 w-full text-base" variant="primary">
              {t('goBack')}
            </Button>
          </Link>
        </>
      ) : (
        status == 'NEED_PASSWORD' && (
          <form
            className="flex flex-col gap-3"
            onSubmit={form.onSubmit((values) => {
              invitationComplete
                .mutateAsync({
                  linkCode: values.linkCode,
                  password: values.password,
                })
                .then(() => {
                  navigate('/auth/signin');
                  ReactGA.event({
                    action: 'activation',
                    category: 'activation',
                    label: 'activation',
                  });
                });
            })}
          >
            <h2 className="text-center text-4xl text-gray-900 font-bold">{t('auth.welcome')}</h2>

            <PasswordInput
              label={t('auth.newPassword')}
              maxLength={30}
              name="password"
              placeholder="********"
              required
              {...form.getInputProps('password')}
              className="mt-5"
            />

            <PasswordInput
              label={t('auth.confirmPassword')}
              maxLength={30}
              name="confirmPassword"
              placeholder="********"
              required
              {...form.getInputProps('confirmPassword')}
              className="mt-5"
            />

            <Button className="w-full mt-8" loading={invitationComplete.isLoading} type="submit">
              {t('auth.finishRegister')}
            </Button>
          </form>
        )
      )}
    </div>
  );
};

export default AuthInvitationPage;
