import clsx from 'clsx';
import { t } from 'i18next';

import { ChevronDownIcon } from '@chess-class/sandbox/icons';

import { useCoachContext } from '~/context/CoachContext';

export default function ProfileAvatar({
  hideSm,
  showCaret,
  showSchool,
}: {
  hideSm?: boolean;
  showCaret?: boolean;
  showSchool?: boolean;
}) {
  const { coachMe, school } = useCoachContext();

  const currentSchool = coachMe?.schools?.find(
    (itemSchool) => itemSchool.schoolId == school?.schoolId,
  );

  return (
    <>
      <div className="h-8 w-8 md:h-10 md:w-10 text-center grid content-center rounded-full border-2 border-gray-200 bg-gray-100 shrink-0">
        <span className="uppercase text-primary text-xs md:text-[15px]">
          {coachMe?.firstName?.[0]}
          {coachMe?.lastName?.[0]}
        </span>
      </div>

      {hideSm && (
        <div className="md:hidden">
          {showCaret && (
            <ChevronDownIcon className="rotate-180 text-gray-500" height={12} width={12} />
          )}
        </div>
      )}

      <div className={clsx(hideSm ? 'hidden md:flex' : 'flex', 'flex-col justify-center')}>
        <div className="flex items-center gap-2">
          <span className="font-bold text-sm capitalize ">
            {`${coachMe?.firstName} ${
              coachMe?.middleName ? `${coachMe.middleName[0]}.` : ''
            } ${coachMe?.lastName}`}
          </span>
          {showCaret && <ChevronDownIcon className="rotate-180" height={12} width={12} />}
        </div>

        {showSchool && (coachMe?.schools?.length || 0) > 1 && !!currentSchool && (
          <span className="text-gray-600 text-xs mt-0.5">{currentSchool.schoolName}</span>
        )}
        <span className="text-gray-600 text-xs">
          {currentSchool?.educationFormat == 'PRIVATE' &&
            !currentSchool?.isSuperCoach &&
            t('coach')}
          {currentSchool?.isSuperCoach && t('superCoach')}
          {currentSchool?.educationFormat == 'GENERAL' &&
            !currentSchool?.isSuperCoach &&
            t('teacher')}
        </span>
      </div>
    </>
  );
}
