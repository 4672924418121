import { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet } from 'react-router-dom';
import { Button } from '@mantine/core';

import { IEnumLocalization } from '@chess-class/sandbox';
import { ArrowBackIcon } from '@chess-class/sandbox/icons';

import LanguageDropdown from '~/components/molecules/LanguageDropdown';
import ProfileDropdown from '~/components/molecules/ProfileDropdown';
import AuthRoute from '~/components/molecules/routes/AuthRoute';
import { SideNavbar } from '~/components/organisms/SideNavbar';
import { CoachContext } from '~/context/CoachContext';
import { usePageMetaContext } from '~/context/PageMetaContext';

const AdminLayout: FC = () => {
  const { goBack } = usePageMetaContext();
  const { t } = useTranslation();
  const coachContext = useContext(CoachContext);

  const filteredLanguages = useMemo(() => {
    if (coachContext.school?.educationFormat == 'GENERAL') {
      return Object.keys(IEnumLocalization).filter((value) => value != 'en');
    } else return Object.keys(IEnumLocalization);
  }, [coachContext]);

  return (
    <AuthRoute auth>
      <div className="h-full w-full flex overflow-hidden">
        <SideNavbar />

        <div className="flex flex-col min-w-0 min-h-0 w-full h-full overflow-hidden">
          <div className="flex items-center justify-between bg-white px-6 h-20 border-b grow-0 shrink-0">
            {!!goBack && (
              <div className="flex items-center">
                <Link to={goBack?.url || '..'}>
                  <Button
                    className="py-3 border-gray-101 font-normal"
                    leftIcon={<ArrowBackIcon className="w-4 h-4 text-gray-400" />}
                    variant="outline"
                  >
                    {t((goBack?.i18Title as 'back') || 'back')}
                  </Button>
                </Link>
              </div>
            )}

            <div className="flex items-center ml-auto gap-8">
              <LanguageDropdown languages={filteredLanguages} />
              <ProfileDropdown />
            </div>
          </div>

          <div className="flex-grow overflow-y-auto overflow-x-hidden p-6 bg-[#FAFAFA]">
            <Outlet />
          </div>
        </div>
      </div>
    </AuthRoute>
  );
};

export default AdminLayout;
