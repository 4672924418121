import { FunctionComponent, SVGProps } from 'react';
import { t } from 'i18next';

import { IEnumEducationFormatKeys } from '@chess-class/sandbox';
import {
  Assignment2Icon,
  BookOpenIcon,
  ContactsIcon,
  FiltersIcon,
  NotebookIcon,
  PlayCircleIcon,
  StudentIcon,
} from '@chess-class/sandbox/icons';

export interface SidebarInterface {
  children?: SidebarInterface[];
  icon?: FunctionComponent<Omit<SVGProps<SVGSVGElement>, 'ref'>>;
  isDev?: boolean;
  isSuperCoach?: boolean;
  key: string;
  link?: string;
  title?: string;
}

export const sidebars: ({
  coachEmail,
  educationFormat,
}: {
  coachEmail?: string;
  educationFormat?: IEnumEducationFormatKeys;
}) => (SidebarInterface | undefined)[] = ({
  coachEmail,
  educationFormat,
}: {
  coachEmail?: string;
  educationFormat?: IEnumEducationFormatKeys;
}) => {
  if (!coachEmail || coachEmail == 'demochessclass@gmail.com') {
    return [
      {
        icon: NotebookIcon,
        key: 'chapters',
        link: 'chapters',
        title: t('chapters'),
      },
    ];
  } else {
    return [
      {
        children: [
          {
            key: 'my',
            link: '',
            title:
              educationFormat == 'GENERAL' ? t('classesPage.myClasses') : t('classesPage.myGroups'),
          },
          {
            key: 'school',
            link: 'school',
            title: educationFormat == 'GENERAL' ? t('allSchoolClasses') : t('allSchoolGroups'),
          },
          {
            key: 'students',
            link: 'students',
            title: t('allSchoolStudents'),
          },
        ],
        icon: ContactsIcon,
        isSuperCoach: true,
        key: 'classes',
        link: 'classes',
        title: educationFormat == 'GENERAL' ? t('class') : t('group'),
      },
      {
        icon: ContactsIcon,
        isSuperCoach: false,
        key: 'classes-my',
        link: 'classes',
        title:
          educationFormat == 'GENERAL' ? t('classesPage.myClasses') : t('classesPage.myGroups'),
      },
      {
        icon: StudentIcon,
        isSuperCoach: true,
        key: 'coaches',
        link: 'coaches',
        title: t('coaches'),
      },
      {
        icon: NotebookIcon,
        key: 'chapters',
        link: 'chapters',
        title: t('chapters'),
      },
      {
        icon: BookOpenIcon,
        key: 'homework',
        link: 'homework',
        title: t('homeworks'),
      },
      {
        icon: Assignment2Icon,
        key: 'classQuiz',
        link: 'classQuiz',
        title: t('assignments'),
      },
      {
        icon: PlayCircleIcon,
        key: 'studio',
        link: 'studio',
        title: t('studio'),
      },
      {
        icon: FiltersIcon,
        key: 'analyze-game',
        link: 'analyze-game',
        title: t('analysisBoard'),
      },
      // {
      //   icon: DevIcon,
      //   isDev: true,
      //   key: 'dev',
      //   link: 'dev',
      //   title: 'Dev',
      // },
    ];
  }
};
