import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Button, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { api, IBackendError, ICoachResetInvokeRequest } from '@chess-class/sandbox';

import { PageMeta } from '~/context/PageMetaContext';

const AuthResetPage: FC = () => {
  const { t } = useTranslation();
  const resetInvoke = api.user.coachAuth.useCoachResetInvoke();
  const [isResetSend, setIsResetSend] = useState(false);

  const form = useForm<ICoachResetInvokeRequest>({
    initialValues: api.user.coachAuth.helpers.coachResetInvokeInitial,
    validate: yupResolver(api.user.coachAuth.helpers.coachResetInvokeValidation),
  });

  return (
    <div>
      <PageMeta title={t('auth.recoverPassword')} />

      {!isResetSend ? (
        <form
          className="flex flex-col gap-2"
          onSubmit={form.onSubmit(({ email }) => {
            resetInvoke
              .mutateAsync({ email: email.trim() })
              .then(() => setIsResetSend(true))
              .catch((error: AxiosError<IBackendError>) => {
                if (error.response?.data?.message == 'User.inactive') {
                  form.setFieldError('email', t('auth.userInactive'));
                }
              });
          })}
        >
          <h2 className="text-center text-2xl sm:text-3xl md:text-4xl text-gray-900 font-bold">
            {t('auth.resetPassword')}
          </h2>

          <p className="text-center mb-8 mt-6">{t('auth.resetPasswordMessage')}</p>

          <TextInput
            label={t('auth.email')}
            name="email"
            {...form.getInputProps('email')}
            placeholder={t('auth.enterEmail')}
          />

          <div className="mt-8">
            <Button className="w-full" loading={resetInvoke.isLoading} type="submit">
              {t('auth.send')}
            </Button>

            <Link className="text-center" to="/auth/signin">
              <Button className="mt-2 w-full text-base" variant="subtle">
                {t('goBack')}
              </Button>
            </Link>
          </div>
        </form>
      ) : (
        <div>
          <h2 className="text-center text-2xl sm:text-3xl md:text-4xl text-gray-900 mb-6 font-bold">
            {t('auth.checkEmail')}
          </h2>
          <p className="text-[#667085] text-base text-center mb-8">
            {t('auth.pleaseCheckEmail')}
            <br /> &quot;{form.values.email}&quot;. {t('auth.sendConfirmation')}
          </p>
          <Button
            className="bg-color-[#11122C] w-full"
            onClick={() => setIsResetSend(false)}
            type="submit"
          >
            {t('auth.changeEmail')}
          </Button>
          <Link className="text-center" to="/auth/signin">
            <Button className="mt-2 w-full text-base" variant="subtle">
              {t('goBack')}
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default AuthResetPage;
