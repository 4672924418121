import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { IEnumLocalization } from '@chess-class/sandbox';

import LanguageDropdown from '~/components/molecules/LanguageDropdown';

const AuthLayout: FC = () => {
  return (
    <div className="min-h-full bg-[#f5f5f5] flex flex-col items-center py-6 md:py-12 px-4 sm:px-6 lg:px-8 gap-8">
      <div className="flex flex-row justify-end gap-8 w-full md:w-1/2">
        <LanguageDropdown languages={Object.keys(IEnumLocalization)} />
      </div>
      <div className="bg-transparent max-w-lg w-full items-center flex flex-col justify-center m-auto">
        <div className="container">
          <div className="p-8 md:p-12 lg:p-16 bg-white rounded-2xl">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
