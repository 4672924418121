import React, { FC, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Button, Modal, Navbar, NavLink, Stack } from '@mantine/core';

import { api } from '@chess-class/sandbox';
import { storageKeys } from '@chess-class/sandbox/consts';

import { SidebarInterface, sidebars } from '~/consts/sidebars';
import { CoachContext } from '~/context/CoachContext';
import { PageMetaContext } from '~/context/PageMetaContext';

function ChessNavLinkWrapper({
  children,
  isLink,
  link,
}: {
  children: JSX.Element;
  isLink: boolean;
  link: string;
}) {
  if (isLink) {
    return <Link to={link}>{children}</Link>;
  }
  return children;
}

function ChessNavLink({
  item,
  openMenuKeys,
  parentKey,
  parentLink,
  selectedMenuKeys,
}: {
  item: SidebarInterface;
  openMenuKeys?: string[];
  parentKey?: string;
  parentLink?: string;
  selectedMenuKeys?: string[];
}) {
  const currentKey = parentKey ? `${parentKey}-${item.key}` : item.key;
  const currentLink = parentLink ? `${parentLink}/${item.link}` : item.link;
  const isActive = selectedMenuKeys?.includes(currentKey);
  const isOpen = openMenuKeys?.includes(currentKey);
  const Icon = item.icon;

  return (
    <ChessNavLinkWrapper isLink={!item.children?.length} link={currentLink || ''}>
      <NavLink
        active={isActive}
        childrenOffset={0}
        classNames={{
          root: clsx(!parentKey ? 'm-0 p-4' : '', isActive ? 'active' : isOpen ? 'open' : ''),
        }}
        defaultOpened={isOpen}
        icon={Icon ? <Icon /> : undefined}
        key={currentKey}
        label={item.title}
        onClick={() => localStorage.setItem(storageKeys.fen, '')}
        variant="sidebar"
      >
        {item.children?.map((subItem) => (
          <ChessNavLink
            item={subItem}
            key={`subItem-${currentKey}-${subItem.key}`}
            openMenuKeys={openMenuKeys}
            parentKey={currentKey}
            parentLink={currentLink}
            selectedMenuKeys={selectedMenuKeys}
          />
        ))}
      </NavLink>
    </ChessNavLinkWrapper>
  );
}

export const SideNavbar: FC = () => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { openMenuKeys, selectedMenuKeys } = useContext(PageMetaContext);
  const { school } = useContext(CoachContext);
  const coachContext = useContext(CoachContext);

  const appSettings = api.settings.useSettings({
    settingNames: ['SUPPORT_EMAIL'],
  });

  const supportEmail = appSettings.data?.data.content[0].string ?? '';

  const [openHelpModal, setOpenHelpModal] = useState(false);

  const links = useMemo(
    () =>
      sidebars({
        coachEmail: coachContext.coachMe?.email,
        educationFormat: coachContext.school?.educationFormat,
      })
        .filter(
          (item) =>
            (item?.isSuperCoach === undefined || school?.isSuperCoach === item.isSuperCoach) &&
            (!item?.isDev || process.env.REACT_APP_API_URL.includes('api.dev')),
        )
        .map(
          (item) =>
            item && (
              <ChessNavLink
                item={item!}
                key={item?.key}
                openMenuKeys={openMenuKeys}
                selectedMenuKeys={selectedMenuKeys}
              />
            ),
        ),
    [language, openMenuKeys, selectedMenuKeys, school?.isSuperCoach],
  );

  return (
    <>
      <Navbar className="!p-0 !z-0 overflow-auto" p="xs" width={{ base: 70, sm: 250 }}>
        <Navbar.Section className="h-20 border-b">
          <Link
            className={clsx(
              'flex text-black items-center justify-center gap-4 p-4 mx-auto overflow-hidden',
            )}
            to="/"
          >
            <div className="p-1 sm:p-2 bg-[#F7F7FB] rounded-lg ml-3 md:ml-0">
              <img alt=" logo" className="w-7 sm:w-8 h-7 sm:h-8 object-contain" src="/logo.png" />
            </div>
            <span className={clsx('font-bold text-xl whitespace-nowrap truncate tracking-wide')}>
              Chess <span className="text-primary">Class</span>
            </span>
          </Link>
        </Navbar.Section>

        <Navbar.Section grow mt={30}>
          <Stack spacing="xs">{links}</Stack>
        </Navbar.Section>

        <Navbar.Section>
          <Button
            className="pl-8 w-full text-left"
            // icon={<QuestionCircleOutlined />}
            onClick={() => setOpenHelpModal(true)}
            variant="suddle "
          >
            {t('help')}
          </Button>
        </Navbar.Section>
      </Navbar>

      <Modal
        centered
        className="px-4 md:px-0 md:w-3/4 lg:w-2/3"
        onClose={() => {
          setOpenHelpModal(false);
        }}
        opened={openHelpModal}
        title={
          <h1 className="font-bold text-2xl m-0 space-x-1">
            <span>{t('help')}</span>
          </h1>
        }
      >
        <div className="flex flex-col justify-between items-center md:w-4/5 m-auto">
          <div className="p-1 sm:p-2">
            <img
              alt="paper"
              className="object-contain h-36 w-auto"
              src="/images/illustrations/paper-plane.png"
            />
          </div>
          <h1 className="font-bold text-2xl m-0 my-6 space-x-1">{t('helpModal.haveQuestions')}</h1>
          <p className="text-base text-gray-600 text-center font-semibold">
            {t('helpModal.helpText')}
          </p>
          <Link
            className="my-6"
            onClick={(e) => {
              window.location.href = `mailto:${supportEmail}`;
              e.preventDefault();
            }}
            to={supportEmail}
          >
            {supportEmail}
          </Link>
        </div>
      </Modal>
    </>
  );
};
